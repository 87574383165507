.navbar {
  text-transform: uppercase;
  display: flex;
  padding-top: 6px;
  position: absolute;
  width: 100%;
  cursor: default;

  .logoAndLocations {
    position: absolute;
    left: 10px;
    display: flex;
    gap: 75px;
  }

  .studioDescription {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
  }

  .navLinks {
    position: absolute;
    list-style: none;
    display: flex;
    right: 10px;
    gap: 10px;
  }
}

@media (max-width: 999px) {
  .navbar {
    .studioDescription {
      display: none;
    }

  }
}

@media (max-width: 600px) {
  .navbar {
    padding-top: 6px;
    .logoAndLocations {
      display: none;
    }

    .navLinks {
      left: 2.5%;
      width: 95%;
      justify-content: space-between;
    }
  }
}

@media (max-width: 600px) and (max-height: 550px) {
  .navbar {
    .logoAndLocations {
      display: flex;
      top: calc(100vh - 24px);
      justify-content: center;
      gap: 35px;
      left: 0;
      width: 100%;
    }
  }
}