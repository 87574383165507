.logo {
  display: none;
  position: absolute;
  bottom: 0px;
  width: calc(100% - 20px);
  left: 10px;

  svg {
    height: 100%;
  }
}

@media (max-width: 600px) {
  .logo {
    display: block;
  }
}

@media (max-width: 600px) and (max-height: 550px) {
  .logo {
    display: none;
  }
}