.mainPage {
  height: 100px;

  .bodyStudioDescription {
    position: absolute;
    top: 250px;
    left: 108px;
    display: none;
  }

  .logo {
    position: absolute;
    bottom: 0px;
    width: calc(100% - 20px);
    left: 10px;
    svg {
      height: 100%;
    }
  }
}

@media (max-width: 999px) {
  .mainPage {
    .bodyStudioDescription {
      span {
        display: none;
      }
      display: block;
      width: 400px;
      top: calc((100vh - 45vw) / 2);
    }
  }
}

@media (max-width: 999px) and (max-height: 52vw) {
  .logo {
    display: none;
  }
  .mainPage .bodyStudioDescription {
    top: 30%;
  }
}

@media (max-width: 600px) {
  .mainPage {
    .bodyStudioDescription {
      top: 150px;
      left: 10px;
      width: 240px;
      span {
        display: inline;
      }
    }
  }
}

@media (max-width: 600px) and (max-height: 550px) {
  .mainPage {
    .logo {
      display: none;
    }
    .bodyStudioDescription span {
      display: none;
    }

  }
}

@media (max-height: 47vw) {
  .logo {
    display: none;
  }
}