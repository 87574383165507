@font-face {
  src: url('/public/font/SuisseIntl-Medium.otf');
  font-family: 'SuisseIntl Medium';
}

::selection {
  color: grey;
}

::-moz-selection {
  color: grey;
}

body {
  margin: 0;
  font-size: 12px;
  font-family: 'SuisseIntl Medium';
  height: -webkit-fill-available;;
  overflow: hidden;
}

button {
  background: none;
  border: none;
  color: inherit;
  padding: 0;
  cursor: pointer;

  font-size: 12px;
  font-family: 'SuisseIntl Medium';
}


code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

* {
  margin: 0;
  padding: 0;
}

p {
  cursor: default;
}

a {
  text-decoration: none;
  color: inherit;
  cursor: pointer;
}

.popupText {
  width: 35%;
  position: absolute;
  top: 250px;
  left: 107px;
  text-transform: uppercase;
}


@media (max-width: 999px) {
  .popupText {
    top: calc((100vh - 45vw) / 2);
  }
}

@media (max-width: 999px) and (max-height: 52vw) {
  .popupText {
    top: 30%;
  }
}

@media (max-width: 600px) {
  .popupText {
    top: 150px;
    left: 2.5%;
    width: 230px;
  }
}

@media (max-height: 450px) {
  .popupText {
    top: 80px;
  }
}