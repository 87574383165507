.imprint {
  position: absolute; 
  left: 50%;
  transform: translateX(-50%);
  top: 250px;
  width: 45%;
  height: 35%;
  text-transform: uppercase;
  overflow: scroll;
  font-size: 12px;

  p {
    width: 100%;
  }
}

.imprint::-webkit-scrollbar {
  display: none;
}

.imprintButton {
  text-transform: uppercase;
  position: absolute;
  bottom: 20px;
  left: 108px;
  width: 100px;
  text-align-last: left;
  cursor: pointer;
  font-size: 12px;
}


.logo {
  
  display: none;
  position: absolute;
  bottom: 0px;
  width: calc(100% - 20px);
  left: 10px;

  svg {
    height: 100%;
  }
}


@media (max-width: 999px) {
  .imprint {
    transform: translateX(0%);
    text-align: left;
    position: fixed;
    top: calc((100vh - 45vw + 140px) / 2);
    left: 108px;
    width: 300px;
    overflow-y: scroll;
  }
}

@media (min-width: 1000px) and (max-height: 450px) {
  .imprint {
    top: 80px;
  }
  .imprintButton {
    font-size: 12px;
  }
}

@media (max-width: 999px) and (max-height: 52vw) {
  .imprint {
    top: calc(30% + 40px);
  }
  .imprintButton {
    font-size: 12px;
  }
}

@media (max-width: 600px) {
  .logo {
    display: block;
  }

  .imprintButton {
    top: 200px;
    left: 2.5%;
    bottom: auto;
  }

  .imprint {
    top: 230px;
    left: 2.5%;
    width: 300px;
    height: 25%;
  }
}
@media (max-width: 600px) and (max-height: 550px) {
  .logo {
    display: none;
  }
}

@media (max-width: 600px) and (max-height: 450px) {
  .imprint {
    top: 140px;
  }
  .imprintButton {
    top: 120px;
  }
}